import {API} from '../api';

export const photoService = {
  uploadPublisherImage,
  changeUserAvatar,
};

function uploadPublisherImage(publisherId, avatar) {
  return API.post(`/photo/publisher?publisherId=${publisherId}`, avatar)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function changeUserAvatar(userId, avatar) {
  return API.post(`/photo/user?userId=${userId}`, avatar)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
