import { API } from "./api";
import { isNullOrEmpty } from "../utils";

export const congregationService = {
  loadCongregations,
  saveCongregation,
  deleteCongregation
};

function loadCongregations() {
  return API.get("/congregations")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function saveCongregation(id, congregation) {
  if (isNullOrEmpty(id)) {
    return API.post(`/congregations/`, congregation)
      .then((resp) => {
        return resp?.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  return API.put(`/congregations/${id}`, congregation)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteCongregation(id) {
  return API.delete(`/congregations/${id}`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
