export const publisherConsts = {
  LOAD_REQUEST: '[Publisher] LOAD_REQUEST',
  LOAD_SUCCESS: '[Publisher] LOAD_SUCCESS',
  LOAD_FAILURE: '[Publisher] LOAD_FAILURE',

  SELECTED_PAGE_CHANGED: '[Publisher] SELECTED_PAGE_CHANGED',
  ITEMS_PER_PAGE_CHANGED: '[Publisher] ITEMS_PER_PAGE_CHANGED',
  TABLE_FILTER_CHANGED: '[Publisher] TABLE_FILTER_CHANGED',

  ADD_PUBLISHER: '[Publisher] ADD_PUBLISHER',
  EDIT_PUBLISHER: '[Publisher] EDIT_PUBLISHER',

  DELETE_PUBLISHER_REQUEST: '[Publisher] DELETE_PUBLISHER_REQUEST',
  DELETE_PUBLISHER_SUCCESS: '[Publisher] DELETE_PUBLISHER_SUCCESS',
  DELETE_PUBLISHER_FAILURE: '[Publisher] DELETE_PUBLISHER_FAILURE',

  LOAD_DETAILS_REQUEST: '[Publisher] LOAD_DETAILS_REQUEST',
  LOAD_DETAILS_SUCCESS: '[Publisher] LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAILURE: '[Publisher] LOAD_DETAILS_FAILURE',

  SAVE_REQUEST: '[Publisher] SAVE_REQUEST',
  SAVE_SUCCESS: '[Publisher] SAVE_SUCCESS',
  SAVE_FAILURE: '[Publisher] SAVE_FAILURE',

  CHANGE_AVATAR_REQUEST: '[Publisher] CHANGE_AVATAR_REQUEST',
  CHANGE_AVATAR_SUCCESS: '[Publisher] CHANGE_AVATAR_SUCCESS',
  CHANGE_AVATAR_FAILURE: '[Publisher] CHANGE_AVATAR_FAILURE',
};
