export const getQueryValue = (query, param) => {
  if (!query)
    return '';

  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let name = vars[i].substr(0, vars[i].indexOf('='));
    let value = vars[i].substr(vars[i].indexOf('=') + 1);
    if (decodeURIComponent(name) === param) {
      return decodeURIComponent(value);
    }
  }

  return '';
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
