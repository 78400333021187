import { alertConsts } from "../const";

export const alertActions = {
  warning,
  error,
  success,
  clear,
  clearAll,
  clearByType
};

function warning(value) {
  if (!value)
    return;

  if (value.data && value.data.errors) {
    return { type: alertConsts.SHOW_WARNING, value: value.data.errors.message };
  }

  return { type: alertConsts.SHOW_WARNING, value };
}

function error(value) {
  if (!value)
    return;

  if (value.data && value.data.errors) {
    const message = {
      id: `f${(~~(Math.random() * 1e8)).toString(16)}`,
      message: value.data.errors.message
    };
    return { type: alertConsts.SHOW_ERROR, value: message };
  }

  return { type: alertConsts.SHOW_ERROR, value };
}

function clear(id) {
  if (!id)
    return;

  return { type: alertConsts.CLEAR_NOTIFICATION, id };
}

function clearByType(notificationType) {
  return { type: alertConsts.CLEAR_BY_TYPE, notificationType };
}

function success(value) {
  if (!value)
    return;

  if (value.data && value.data.errors) {
    return { type: alertConsts.SHOW_SUCCESS, value: value.data.errors.message };
  }

  return { type: alertConsts.SHOW_SUCCESS, value };
}

function clearAll() {
  return { type: alertConsts.CLEAR_ALL };
}
