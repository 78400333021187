import {API} from "../api";

export const rolesService = {
  getRoles,
};

function getRoles() {
  return API.get("/roles")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
