export const chartLineOptions = (max) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 15,
            stepSize: Math.ceil(max / 5),
            max: max,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 1,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  };
};
