import moment from 'moment';
import {format, parseISO, formatDistanceToNow, isValid, getDate, getYear, getMonth} from 'date-fns';
import {uk} from 'date-fns/locale';
import _ from 'lodash';

export function updateDateFormat(params) {
  const d = moment(params.value);
  const utc = moment.utc(d).toDate();
  const local = moment(utc).local().format('DD/MM/YYYY HH:mm:ss');
  return local.toString();
}

export const now = () => {
  return format(getDate(new Date()), 'dd/MM/yyyy HH:mm:ss');
};

export function dateFormat(date) {
  if (!date) return date;

  const local = moment(date).format('yyyy-MM-DD');
  return local.toString();
}

export const age = (dateStr) => {
  let date = parseISO(dateStr);

  if (!isValid(date)) return '';

  return formatDistanceToNow(date, {addSuffix: false, locale: uk});
};

export const formatUpdateDate = (updateDate) => {
  if (!updateDate) return '';

  return format(parseISO(updateDate), 'dd/MM/yyyy HH:mm:ss', {locale: uk});
};

export const formatDate = (date) => {
  if (!date) return '';

  return format(parseISO(date), 'dd/MM/yyyy', {locale: uk});
};

export const currentYear = () => {
  return getYear(new Date());
};

export const currentMonth = () => {
  return getMonth(new Date());
};

export const previousYear = () => {
  return getYear(new Date()) - 1;
};

export const getMonthName = (month) => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months[i] = uk.localize.month(i);
  }
  return _.capitalize(months[month - 1]);
};

export const getMonths = () => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months[i] = uk.localize.month(i);
  }
  return months.map((month, index) => ({
    key: index + 1,
    value: _.capitalize(month),
  }));
};
