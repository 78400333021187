import {notificationConsts} from '../const';

export function notifications(
  state = {
    severity: null,
    show: null,
    message: null,
  },
  action
) {
  const {severity, message} = action;

  if (message) {
    return {
      severity: severity,
      message: message,
      show: true,
    };
  }

  if (action.type === notificationConsts.HIDE_NOTIFICATION) {
    return {
      message: null,
      severity: null,
      show: false,
    };
  }

  return state;
}
