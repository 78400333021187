import {publisherService} from '../services';
import {publisherConsts} from '../const';
import {history, errorToast, successToast, showError} from '../utils';
import {photoService} from '../services/administration/photo.service';
import CykorNotificationManager from '../helpers/CykorNotificationManager';
export const publisherActions = {
  loadPublishers,
  addPublisher,
  editPublisher,
  deletePublisher,
  publisherDetails,
  savePublisher,
  changePublisherAvatar,
  selectedPageChanged,
  itemsPerPageChanged,
  tableFilterChanged,
};

function loadPublishers() {
  return (dispatch) => {
    dispatch(request());

    publisherService
      .loadPublishers()
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: publisherConsts.LOAD_REQUEST};
  }
  function success(publishers) {
    return {type: publisherConsts.LOAD_SUCCESS, publishers};
  }
  function failure(error) {
    return {type: publisherConsts.LOAD_FAILURE, error};
  }
}

function addPublisher() {
  return (dispatch) => {
    const publisher = {
      id: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      dateOfBirth: '',
      dateOfBaptism: '',
      gender: -1,
      phone: '',
      mobilePhone: '',
      mobilePhoneEx: '',
      photoUrl: '',
      country: '',
      city: '',
      address: '',
      comment: '',
      email: '',
      congregationId: '',
      privileges: 0,
      activeStatus: 1,
      updateUser: '',
      updateDate: '2020-01-01T12:00:00.0205843Z',
    };

    dispatch(add(publisher));
    history.push('/publishers/add');
  };

  function add(publisher) {
    return {type: publisherConsts.ADD_PUBLISHER, value: publisher};
  }
}

function editPublisher(publisher) {
  return (dispatch) => {
    dispatch(edit(publisher));
    history.push(`publishers/${publisher.id}`, publisher);
  };

  function edit(publisher) {
    return {type: publisherConsts.EDIT_PUBLISHER, value: publisher};
  }
}

function selectedPageChanged(pageNumber) {
  return {type: publisherConsts.SELECTED_PAGE_CHANGED, value: pageNumber};
}

function itemsPerPageChanged(itemsPerPage) {
  return {type: publisherConsts.ITEMS_PER_PAGE_CHANGED, value: itemsPerPage};
}

function tableFilterChanged(filter) {
  return {type: publisherConsts.TABLE_FILTER_CHANGED, value: filter};
}

function deletePublisher(id, reason, comment) {
  return (dispatch) => {
    dispatch(request());

    publisherService
      .deletePublisher(id, reason, comment)
      .then(
        (resp) => {
          dispatch(success(resp));
          successToast('Вісника було успішно видалено');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: publisherConsts.DELETE_PUBLISHER_REQUEST};
  }
  function success(publisher) {
    return {type: publisherConsts.DELETE_PUBLISHER_SUCCESS, publisher};
  }
  function failure(error) {
    return {type: publisherConsts.DELETE_PUBLISHER_FAILURE, error};
  }
}

function publisherDetails(id) {
  return (dispatch) => {
    dispatch(request());

    publisherService
      .publisherDetails(id)
      .then((resp) => {
        dispatch(success(resp));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {type: publisherConsts.LOAD_DETAILS_REQUEST};
  }
  function success(publisher) {
    return {type: publisherConsts.LOAD_DETAILS_SUCCESS, publisher};
  }
  function failure(error) {
    return {type: publisherConsts.LOAD_DETAILS_FAILURE, error};
  }
}

function savePublisher(id, publisher) {
  return (dispatch) => {
    dispatch(request());
    publisherService
      .savePublisher(id, publisher)
      .then(
        (resp) => {
          CykorNotificationManager.success('Зміни успішно внесені');
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          showError(error);
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        showError(error);
        errorToast(error);
      });
  };

  function request() {
    return {type: publisherConsts.SAVE_REQUEST};
  }
  function success(publisher) {
    return {type: publisherConsts.SAVE_SUCCESS, publisher};
  }
  function failure(error) {
    return {type: publisherConsts.SAVE_FAILURE, error};
  }
}

function changePublisherAvatar(id, avatar) {
  return (dispatch) => {
    dispatch(request());

    photoService
      .uploadPublisherImage(id, avatar)
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: publisherConsts.CHANGE_AVATAR_REQUEST};
  }
  function success(url) {
    return {type: publisherConsts.CHANGE_AVATAR_SUCCESS, url};
  }
  function failure(error) {
    return {type: publisherConsts.CHANGE_AVATAR_FAILURE, error};
  }
}
