import { congregationMonthReportConsts } from '../const';

export function congregationMonthReports(state = {}, action) {
  switch (action.type) {
    case congregationMonthReportConsts.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case congregationMonthReportConsts.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.reports
      };
    case congregationMonthReportConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
