import React from 'react';
import jwt_decode from 'jwt-decode';
import {Route, Redirect} from 'react-router-dom';
import {ACCESS_TOKEN_KEY} from '../../const';

export const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={(props) => {
      let token = localStorage.getItem(ACCESS_TOKEN_KEY);
      let expired = false;
      if (token) {
        let decoded = jwt_decode(token);

        expired = decoded.exp < Date.now() / 1000;

        if (expired) localStorage.clear();
      }

      if (!token || expired) {
        return <Redirect to={{pathname: '/login', state: {from: props.location, referrer: props.location}}} />;
      }

      return <Component {...props} />;
    }}
  />
);
