import {NotificationManager} from 'react-notifications';
import {CykorNotificationManager} from '../helpers';

const parseMessage = (message) => {
  if (message.data && message.data.detail) return message.data.detail;

  console.log(message);
  if (message?.status === 400 && message?.title) {
    return message.title;
  }
  if (message?.status === 500 && message?.statusText) {
    return message.statusText;
  }
  if (message?.data?.errors) {
    let data = message?.data;

    if (data && Object.keys(data.errors).length > 0) {
      if (data.errors?.message) return data.errors.message;
      if (data.errors[0].Description) return data.errors[0].Description;
      return data.errors[0].toString();
    }
  }
  if (message?.data?.message) {
    return message.data.message;
  }
  if (message?.status !== 200 && message?.data) {
    if (Object.keys(message.data).length > 0) return message.data[0].description;
    return message.data;
  }

  return message;
};

export const showToast = (type, header, message) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message, header);
      break;
    case 'success':
      NotificationManager.success(message, header);
      break;
    case 'warning':
      NotificationManager.warning(message, header);
      break;
    case 'error':
      NotificationManager.error(message, header);
      break;
    default:
      NotificationManager.info(message, header);
      break;
  }
};

export const showNotification = (type, message, title, timeOut, onClick, priority) => {
  switch (type) {
    case 'info':
      CykorNotificationManager.info(message, title, timeOut, onClick, priority);
      break;
    case 'success':
      CykorNotificationManager.success(message, title, timeOut, onClick, priority);
      break;
    case 'warning':
      CykorNotificationManager.warning(message, title, timeOut, onClick, priority);
      break;
    case 'error':
      CykorNotificationManager.error(message, title, timeOut, onClick, priority);
      break;
    default:
      CykorNotificationManager.info(message, title, timeOut, onClick, priority);
      break;
  }
};

export const showSuccess = (message, title, timeOut, onClick, priority) => {
  showNotification('success', message, title, timeOut, onClick, priority);
};

export const showError = (message, title, timeOut, onClick, priority) => {
  showNotification('error', message, title, timeOut, onClick, priority);
};

export const successToast = (message) => {
  return showToast('success', 'Успіх', parseMessage(message));
};

export const infoToast = (message) => {
  return showToast('info', 'Інформація', parseMessage(message));
};

export const warningToast = (message) => {
  return showToast('warning', 'Попередження', parseMessage(message));
};

export const errorToast = (message) => {
  return showToast('error', 'Помилка', parseMessage(message));
};

export const severityToString = (severity) => {
  switch (severity) {
    case 'info':
      return 'Інформація';
    case 'success':
      return 'Успіх';
    case 'warning':
      return 'Попередження';
    case 'error':
      return 'Помилка';
    default:
      return '';
  }
};
