import { API } from "../api";

export const usersService = {
  getUsers,
  link,
  assign,
  deleteUser,
  confirmEmail
};

function getUsers() {
  return API.get("/users/all")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function link(userId, publisherId) {
  return API.post("/users/link", { userId, publisherId })
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function assign(userId, congregationId) {
  return API.post("/users/assign", { userId, congregationId })
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deleteUser(userId) {
  return API.delete("/users/" + userId)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function confirmEmail(userId, code) {
  return API.get("/users/confirmemail", { params: { userId, code } })
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
