import {API} from "../api";

export const settingsService = {
  getSettings,
};

function getSettings() {
  return API.get("/settings")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
