import {authConsts, ACCESS_TOKEN_KEY} from '../const';

let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
const initialState = accessToken ? { loggedIn: true, accessToken } : {};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConsts.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        accessToken: action.accessToken,
        error: null
      };
    case authConsts.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        accessToken: action.accessToken,
        error: null,
      };
    case authConsts.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        error: action.error
      };
    case authConsts.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        passwordResetted: false,
        error: null
      };
    case authConsts.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordResetted: true,
        error: null
      };
    case authConsts.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        passwordResetted: false,
        error: action.error
      };
    case authConsts.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        mailSent: false,
        identifier: action.identifier,
        error: null
      };
    case authConsts.FORGOT_PASSWORD_MAIL_SENT:
      return {
        ...state,
        loading: false,
        mailSent: true,
        error: null
      };
    case authConsts.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        mailSent: false,
        error: action.error
      };
    case authConsts.LOGOUT:
      return {};
    case authConsts.CURRENT_USER:
      return {
        ...state,
        currentUser: action.user
      };
    case authConsts.REGISTER_REQUEST:
      return {
        ...state
      };
    case authConsts.REGISTER_SUCCESS:
      return {
        ...state
      };
    case authConsts.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
