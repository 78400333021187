import {authConsts} from '../const';
import {authService} from '../services';
import {history, errorToast, successToast} from '../utils';

export const authActions = {
  login,
  logout,
  currentUser,
  forgotPassword,
  resetPassword,
  register,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({username}));

    authService
      .login(username, password)
      .then(
        (token) => {
          dispatch(success(token));
          history.push('/dashboard');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request(accessToken) {
    return {type: authConsts.LOGIN_REQUEST, accessToken};
  }
  function success(accessToken) {
    return {type: authConsts.LOGIN_SUCCESS, accessToken};
  }
  function failure(error) {
    return {type: authConsts.LOGIN_FAILURE, error};
  }
}

function register(user) {
  return (dispatch) => {
    dispatch(request());
    authService
      .register(user)
      .then(
        (resp) => {
          dispatch(success());
          successToast('Користувача зареєстровано');
          history.push('login');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: authConsts.REGISTER_REQUEST};
  }
  function success() {
    return {type: authConsts.REGISTER_SUCCESS};
  }
  function failure(error) {
    return {type: authConsts.REGISTER_FAILURE, error};
  }
}

function logout() {
  authService.logout();
  history.push('/login');
  return {type: authConsts.LOGOUT};
}

function forgotPassword(email, userName) {
  return (dispatch) => {
    dispatch(request(email ? email : userName));

    authService
      .forgotPassword(email, userName)
      .then(
        (resp) => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request(identifier) {
    return {type: authConsts.FORGOT_PASSWORD_REQUEST, identifier};
  }
  function success() {
    return {type: authConsts.FORGOT_PASSWORD_MAIL_SENT};
  }
  function failure(error) {
    return {type: authConsts.FORGOT_PASSWORD_FAILURE, error};
  }
}

function resetPassword(userName, code, password) {
  return (dispatch) => {
    dispatch(request());

    authService
      .resetPassword(userName, code, password)
      .then(
        (resp) => {
          dispatch(success());
          history.push('/login');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: authConsts.RESET_PASSWORD_REQUEST};
  }
  function success() {
    return {type: authConsts.RESET_PASSWORD_SUCCESS};
  }
  function failure(error) {
    return {type: authConsts.RESET_PASSWORD_FAILURE, error};
  }
}

function currentUser() {
  return (dispatch) => {
    authService.currentUser().then(
      (user) => {
        dispatch(current(user));
      },
      (error) => {
        errorToast(error);
      }
    );
  };

  function current(user) {
    return {type: authConsts.CURRENT_USER, user};
  }
}
