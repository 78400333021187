import { statisticsConsts } from '../const';

export function statistics(state = {}, action) {
  switch (action.type) {
    case statisticsConsts.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case statisticsConsts.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      };
    case statisticsConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case statisticsConsts.AVG_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case statisticsConsts.AVG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        avgs: action.data
      };
    case statisticsConsts.AVG_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
