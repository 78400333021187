import { API } from "./api";

export const statisticsService = {
  getStatistics,
  getAvg
};

function getStatistics() {
  return API.get("/statistics")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getAvg() {
  return API.get("/statistics/avg")
    .then(resp => {
      return resp?.data;
    })
    .catch((error) => {
    return Promise.reject(error);
    });
}
