import {API} from "../api";

export const congregationRolesService = {
  getCongregationRoles,
};

function getCongregationRoles() {
  return API.get("/congregationroles")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
