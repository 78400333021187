import {publisherConsts} from '../const';

export function publishers(state = {}, action) {
  switch (action.type) {
    case publisherConsts.LOAD_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case publisherConsts.LOAD_SUCCESS:
      const publishers = action.publishers.map((publisher) => ({
        ...publisher,
        name: publisher.lastName + ' ' + publisher.firstName,
      }));
      return {
        ...state,
        items: publishers,
        loading: false,
      };
    case publisherConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.error,
      };
    case publisherConsts.LOAD_DETAILS_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true,
      };
    case publisherConsts.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.publisher,
      };
    case publisherConsts.LOAD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        current: null,
      };
    case publisherConsts.SAVE_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case publisherConsts.SAVE_SUCCESS:
      if (!action.publisher) return {...state, loading: false, success: true};

      return {
        ...state,
        success: true,
        loading: false,
        items: [
          ...state.items,
          {...action.publisher, name: action.publisher.lastName + ' ' + action.publisher.firstName},
        ],
        currentPublisher: action.value,
      };
    case publisherConsts.SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case publisherConsts.SELECTED_PAGE_CHANGED:
      return {
        ...state,
        activePage: action.value,
      };
    case publisherConsts.ITEMS_PER_PAGE_CHANGED:
      return {
        ...state,
        itemsPerPage: action.value,
      };
    case publisherConsts.TABLE_FILTER_CHANGED:
      return {
        ...state,
        tableFilter: action.value,
      };
    case publisherConsts.ADD_PUBLISHER:
      return {
        ...state,
        isAddMode: true,
        currentPublisher: action.value,
      };
    case publisherConsts.EDIT_PUBLISHER:
      return {
        ...state,
        isAddMode: false,
        currentPublisher: action.value,
      };
    case publisherConsts.DELETE_PUBLISHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case publisherConsts.DELETE_PUBLISHER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((publisher) => publisher.id !== action.publisher.id),
      };
    case publisherConsts.DELETE_PUBLISHER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case publisherConsts.CHANGE_AVATAR_SUCCESS:
      return {
        ...state,
        currentPublisher: {
          ...state.currentPublisher,
          photoUrl: action.url,
        },
      };
    default:
      return state;
  }
}
