import {API, API_EX} from './api';

export const congregationMonthReportService = {
  getSummary,
  getReports,
  addMonthReport,
  updateMonthReport,
};

function getSummary() {
  return API.get(`/congregationmonthreports/`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getReports(year) {
  return API.get(`/congregationmonthreports/${year}`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function addMonthReport(values) {
  return API_EX.post(`/congregationmonthreports`, values)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateMonthReport(id, values) {
  return API_EX.put(`/congregationmonthreports/${id}`, values)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
