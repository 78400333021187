import { API } from "./api";

export const groupService = {
  load
};

function load() {
  return API.get("/groups")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
