import {usersService} from '../../services';
import {userConsts} from '../../const';
import {errorToast, successToast} from '../../utils';

export const userActions = {
  loadUsers,
  addUser,
  link,
  assign,
  deleteUser,
  confirmEmail,
};

function loadUsers() {
  return (dispatch) => {
    dispatch(request());

    usersService
      .getUsers()
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: userConsts.LOAD_REQUEST};
  }
  function success(users) {
    return {type: userConsts.LOAD_SUCCESS, users};
  }
  function failure(error) {
    return {type: userConsts.LOAD_FAILURE, error};
  }
}

function addUser() {}

function link(userId, publisherId) {
  return (dispatch) => {
    dispatch(request());

    usersService
      .link(userId, publisherId)
      .then(
        (resp) => {
          dispatch(success());
          dispatch(userActions.loadUsers());
          successToast("Користувача було успішно зв'язано");
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: userConsts.LINK_REQUEST};
  }
  function success() {
    return {type: userConsts.LINK_SUCCESS};
  }
  function failure(error) {
    return {type: userConsts.LINK_FAILURE, error};
  }
}

function assign(userId, congregationId) {
  return (dispatch) => {
    dispatch(request());

    usersService
      .assign(userId, congregationId)
      .then(
        (resp) => {
          dispatch(success());
          dispatch(userActions.loadUsers());
          successToast('Користувача було успішно додано до збору');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: userConsts.ASSIGN_REQUEST};
  }
  function success() {
    return {type: userConsts.ASSIGN_SUCCESS};
  }
  function failure(error) {
    return {type: userConsts.ASSIGN_FAILURE, error};
  }
}

function deleteUser(userId) {
  return (dispatch) => {
    dispatch(request());

    usersService
      .deleteUser(userId)
      .then(
        (resp) => {
          dispatch(success());
          dispatch(userActions.loadUsers());
          successToast('Користувача було успішно видалено');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: userConsts.DELETE_REQUEST};
  }
  function success() {
    return {type: userConsts.DELETE_SUCCESS};
  }
  function failure(error) {
    return {type: userConsts.DELETE_FAILURE, error};
  }
}

function confirmEmail(userId, code) {
  return (dispatch) => {
    dispatch(request());

    usersService
      .confirmEmail(userId, code)
      .then(
        (resp) => {
          dispatch(success());
          successToast('Вітаємо, електронну пошту підтверджено');
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: userConsts.CONFIRM_EMAIL_REQUEST};
  }
  function success() {
    return {type: userConsts.CONFIRM_EMAIL_SUCCESS};
  }
  function failure(error) {
    return {type: userConsts.CONFIRM_EMAIL_FAILURE, error};
  }
}
