import React, {useEffect} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {PrivateRoute} from './components/PrivateRoute/PrivateRoute';
import {history} from './utils';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import {useDispatch} from 'react-redux';
import {clearNotification} from './actions';
import {LoadingIndicator} from './components/LoadingOverlay';

const loading = <LoadingIndicator />;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/Login/Login'));
const Register = React.lazy(() => import('./views/Auth/Register/Register'));
const ConfirmEmail = React.lazy(() => import('./views/Auth/Register/ConfirmEmail'));
const ForgotPassword = React.lazy(() => import('./views/Auth/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Auth/ResetPassword/ResetPassword'));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearNotification()); // clear notification when changing location
    });
  }, [dispatch]);

  return (
    <Router history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path='/login' name='Login Page' render={(props) => <Login {...props} />} />
          <Route path='/register' name='Register Page' render={(props) => <Register {...props} />} />
          <Route
            exact
            path='/forgot-pass'
            name='Forgot Password Page'
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route path='/confirm-email' name='Confirm Email Page' render={(props) => <ConfirmEmail {...props} />} />
          <Route path='/reset-pass' name='Reset Password Page' render={(props) => <ResetPassword {...props} />} />
          <PrivateRoute path='/' name='Home' component={DefaultLayout} />
        </Switch>
      </React.Suspense>
      <NotificationContainer autoClose={2000} />
    </Router>
  );
};

export default App;
