export const authConsts = {
    REGISTER_REQUEST: '[Auth] REGISTER_REQUEST',
    REGISTER_SUCCESS: '[Auth] REGISTER_SUCCESS',
    REGISTER_FAILURE: '[Auth] REGISTER_FAILURE',

    LOGIN_REQUEST: '[Auth] LOGIN_REQUEST',
    LOGIN_SUCCESS: '[Auth] LOGIN_SUCCESS',
    LOGIN_FAILURE: '[Auth] LOGIN_FAILURE',

    LOGOUT: '[Auth] USER_LOGOUT',

    FORGOT_PASSWORD_REQUEST: '[Auth] FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_MAIL_SENT: '[Auth] FORGOT_PASSWORD_MAIL_SENT',
    FORGOT_PASSWORD_FAILURE: '[Auth] FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: '[Auth] RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: '[Auth] RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: '[Auth] RESET_PASSWORD_FAILURE',

    CURRENT_USER: '[Auth] CURRENT_USER',

    GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USER_GET_ALL_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE'
};
