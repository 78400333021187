import {API, API_EX} from './api';

export const publisherService = {
  loadPublishers,
  loadCongregationPublishers,
  publisherDetails,
  savePublisher,
  deletePublisher,
};

function loadPublishers() {
  return API.get('/publishers')
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function loadCongregationPublishers(id) {
  return API.get('/publishers/congregation/' + id)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function publisherDetails(id) {
  return API.get(`/publishers/${id}`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function savePublisher(id, publisher) {
  if (id === -1 || id === '') {
    return API_EX.post(`/publishers/`, publisher)
      .then((resp) => {
        return resp?.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  return API_EX.put(`/publishers/${id}`, publisher)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function deletePublisher(id, reason, comment) {
  return API.post(`/publishers/delete`, {
    id,
    reason,
    comment,
  })
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
