import { congregationConsts } from '../const';

export function congregations(state = {}, action) {
  switch (action.type) {
    case congregationConsts.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case congregationConsts.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.congregations
      };
    case congregationConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}