import React, {useEffect, useMemo, useState} from 'react';
import 'spinkit/spinkit.min.css';
import {API_EX} from '../../services/api';

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = () => setCounter((counter) => counter + 1);
    const dec = () => setCounter((counter) => counter - 1);

    return {
      request: (config) => {
        console.log(config);
        inc();
        return config;
      },
      response: (response) => {
        dec();
        return response;
      },
      error: (error) => {
        dec();
        return Promise.reject(error);
      },
    };
  }, []); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = API_EX.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor = API_EX.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      API_EX.interceptors.request.eject(reqInterceptor);
      API_EX.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};

const styles = {
  text: {
    //marginTop: 80,
    color: '#888',
    marginRight: 16,
  },
  spinner: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const Loader = ({text = 'Опрацювання...', fullPage, containerStyle, textStyle}) => {
  const [loading] = useAxiosLoader();
  if (!loading) {
    return null;
  }
  const mergedContainerStyle = {
    ...styles.spinner,
    ...containerStyle,
    position: fullPage ? 'fixed' : 'absolute',
  };
  return (
    <div style={mergedContainerStyle}>
      <span style={{...styles.text, ...textStyle}}>{text}</span>
      <div className='sk-grid'>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
        <div className='sk-grid-cube'></div>
      </div>
    </div>
  );
};

export default Loader;
