import {roleConsts} from "../../const";

export function roles (state = {}, action) {
  switch (action.type) {
    case roleConsts.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case roleConsts.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.roles
      };
    case roleConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
