import { alertConsts } from "../const";

export function alerts(state = {
  successes: [],
  errors: [],
  warnings: []
}, action) {
  switch (action.type) {
    case alertConsts.SHOW_SUCCESS:
      return {
        ...state,
        successes: [...state.successes, action.value],
      };
    case alertConsts.SHOW_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.value],
      };
    case alertConsts.SHOW_WARNING:
      return {
        ...state,
        warnings: [...state.warnings, action.value],
      };
    case alertConsts.CLEAR_NOTIFICATION:
      return {
        ...state,
        successes: state.successes.filter(n => n.id !== action.id),
        errors: state.errors.filter(n => n.id !== action.id),
        warnings: state.warnings.filter(n => n.id !== action.id)
      };
    case alertConsts.CLEAR_BY_TYPE:
      return {
        ...state,
        successes: action.notificationType === 'success' ? [] : [...state.successes],
        warnings: action.notificationType === 'warning' ? [] : [...state.warnings],
        errors: action.notificationType === 'error' ? [] : [...state.errors],
      };
    case alertConsts.CLEAR_ALL:
      return {
        ...state,
        successes: [],
        errors: [],
        warnings: []
      };
    default:
      return state;
  }
}
