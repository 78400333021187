import {uiConsts} from '../const';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
};

export function ui(state = initialState, action) {
  switch (action.type) {
    case uiConsts.DARK_MODE:
      return {
        ...state,
        darkMode: action.value,
      };
    case uiConsts.ASIDE_SHOW:
      return {
        ...state,
        asideShow: action.value,
      };
    case uiConsts.SIDEBAR_SHOW:
      return {
        ...state,
        sidebarShow: action.value,
      };
    case uiConsts.START_LOADER:
      return {
        ...state,
        loading: action.value,
      };
    case uiConsts.STOP_LOADER:
      return {
        ...state,
        loading: action.value,
      };
    default:
      return state;
  }
}
