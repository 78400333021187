import {uiConsts} from '../const';

export const uiActions = {
  darkMode,
  asideShow,
  sideBarShow,
  startLoader,
  stopLoader,
};

function darkMode(value) {
  return {type: uiConsts.DARK_MODE, value};
}

function asideShow(value) {
  return {type: uiConsts.ASIDE_SHOW, value};
}

function sideBarShow(value) {
  return {type: uiConsts.SIDEBAR_SHOW, value};
}

function startLoader() {
  return {type: uiConsts.START_LOADER, value: true};
}

function stopLoader() {
  return {type: uiConsts.STOP_LOADER, value: false};
}
