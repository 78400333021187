import {roleConsts} from "../../const/administration/role.const";
import {rolesService} from "../../services";
import {alertActions} from "../alert.actions";

export const roleActions = {
  loadRoles,
  addRole
};

function loadRoles() {
  return dispatch => {
    dispatch(request());

    rolesService.getRoles()
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };

  function request() { return { type: roleConsts.LOAD_REQUEST }; }
  function success(roles) { return { type: roleConsts.LOAD_SUCCESS, roles }; }
  function failure(error) { return { type: roleConsts.LOAD_FAILURE, error }; }
}

function addRole() {

}
