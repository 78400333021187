import {API} from "../api";

export const profileService = {
  getProfile,
  updateProfile,
  changePassword
};

function getProfile() {
  return API.get("/profile")
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateProfile(profile) {
  return API.post("/profile", profile)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function changePassword(oldPassword, newPassword) {
  return API.post('/profile/change-pass', {
    oldPassword,
    newPassword
  })
    .then(resp => {
      return resp?.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
