import React from 'react';
import 'spinkit/spinkit.min.css';

const LoadingIndicator = () => {
  return (
    <div className='sk-grid'>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
      <div className='sk-grid-cube'></div>
    </div>
  );
};

export default LoadingIndicator;
