import {congregationRoleConsts} from "../../const";
import {congregationRolesService} from "../../services";
import {alertActions} from "../alert.actions";

export const congregationRolesActions = {
  loadCongregationRoles,
  addCongregationRole
};

function loadCongregationRoles() {
  return dispatch => {
    dispatch(request());

    congregationRolesService.getCongregationRoles()
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };

  function request() { return { type: congregationRoleConsts.LOAD_REQUEST }; }
  function success(congregationRoles) { return { type: congregationRoleConsts.LOAD_SUCCESS, congregationRoles }; }
  function failure(error) { return { type: congregationRoleConsts.LOAD_FAILURE, error }; }
}

function addCongregationRole() {

}
