import {notificationConsts} from '../const';

export const notificationActions = {
  warning,
  error,
  success,
};

export function showNotification(severity, message) {
  clearNotification();

  return {
    type: notificationConsts.SHOW_NOTIFICATION,
    severity: severity,
    message: message,
  };
}

export function clearNotification() {
  return {
    type: notificationConsts.HIDE_NOTIFICATION,
  };
}

function warning(message) {
  if (!message) return;

  return showNotification('warning', message);
}

function error(message) {
  if (!message) return;

  return showNotification('danger', message);
}

function success(message) {
  if (!message) return;

  return showNotification('success', message);
}
