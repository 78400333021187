import {combineReducers} from 'redux';

import {auth} from './auth.reducer';
import {ui} from './ui.reducer';
import {alerts} from './alert.reducer';
import {publishers} from './publisher.reducer';
import {congregations} from './congregation.reducer';
import {statistics} from './statistics.reducer';
import {congregationMonthReports} from './congregationMonthReports.reducer';
import {roles} from './administration/role.reducer';
import {users} from './administration/user.reducer';
import {settings} from './administration/settings.reducer';
import {notifications} from './notification.reducer';
import {congregationRoles} from './administration/congregationRole.reducer';

const rootReducer = combineReducers({
  auth,
  ui,
  alerts,
  roles,
  users,
  settings,
  publishers,
  congregations,
  statistics,
  notifications,
  congregationMonthReports,
  congregationRoles,
});

export default rootReducer;
