import {congregationMonthReportService} from '../services';
import {congregationMonthReportConsts} from '../const';
import {alertActions} from './alert.actions';

export const congregationMonthReportActions = {
  getCongregationMonthReports,
};

function getCongregationMonthReports() {
  return (dispatch) => {
    dispatch(request());

    congregationMonthReportService
      .getSummary()
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      });
  };

  function request() {
    return {type: congregationMonthReportConsts.LOAD_REQUEST};
  }
  function success(reports) {
    return {type: congregationMonthReportConsts.LOAD_SUCCESS, reports};
  }
  function failure(error) {
    return {type: congregationMonthReportConsts.LOAD_FAILURE, error};
  }
}
