import { userConsts } from "../../const";

export function users(state = {}, action) {
  switch (action.type) {
    case userConsts.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConsts.LOAD_SUCCESS:
      const users = action.users.map((user) => ({...user, name: user.lastName + ' ' + user.firstName}));
      return {
        ...state,
        loading: false,
        items: users,
      };
    case userConsts.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConsts.LINK_REQUEST:
      return {
        ...state
      };
    case userConsts.LINK_SUCCESS:
      return {
        ...state
      };
    case userConsts.LINK_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConsts.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConsts.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailConfirmed: true
      };
    case userConsts.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        emailConfirmed: false,
        error: action.error
      };
    default:
      return state;
  }
}
