import {showError} from '../utils';
import {API, API_EX} from './api';

export const reportsService = {
  getCard,
  getReportStats,
  uploadReport,
};

function getCard(id) {
  return API.get('/publisherreports/card/' + id)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getReportStats(year) {
  return API_EX.get(`/publisherreports/summary?year=${year}`)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function uploadReport(data) {
  return API_EX.post(`/publisherreports/import`, data)
    .then((resp) => {
      return resp?.data;
    })
    .catch((error) => {
      showError(error);
      return Promise.reject(error);
    });
}
