export const Privileges = {
  None: 0,
  Elder: 1,
  Servant: 2,
  Pioneer: 4,
  AxPioneer: 8,
  SpecialPioneer: 16,
};

export const fullName = (publisher) => {
  if (publisher) return (publisher.lastName + ' ' + publisher.firstName).trim();

  return '';
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length

  //home number
  if (cleaned.length === 7) {
    let match = cleaned.match(/^(\d{3})(\d{4})$/);

    if (match) {
      return '(032) ' + match[1] + '-' + match[2];
    }
  }

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const getPioneerDesc = (i) => {
  switch (i) {
    case 0:
      return '';
    case 1:
      return 'Допоміжний піонер';
    case 2:
      return 'Піонер';
    default:
      return '';
  }
};
