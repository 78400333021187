export const userConsts = {
  LOAD_REQUEST: '[Users] LOAD_REQUEST',
  LOAD_FAILURE: '[Users] LOAD_FAILURE',
  LOAD_SUCCESS: '[Users] LOAD_SUCCESS',
  LINK_REQUEST: '[Users] LINK_REQUEST',
  LINK_FAILURE: '[Users] LINK_FAILURE',
  LINK_SUCCESS: '[Users] LINK_SUCCESS',
  ASSIGN_REQUEST: '[Users] ASSIGN_REQUEST',
  ASSIGN_FAILURE: '[Users] ASSIGN_FAILURE',
  ASSIGN_SUCCESS: '[Users] ASSIGN_SUCCESS',
  DELETE_REQUEST: '[Users] DELETE_REQUEST',
  DELETE_FAILURE: '[Users] DELETE_FAILURE',
  DELETE_SUCCESS: '[Users] DELETE_SUCCESS',
  CONFIRM_EMAIL_REQUEST: '[Users] CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_FAILURE: '[Users] CONFIRM_EMAIL_FAILURE',
  CONFIRM_EMAIL_SUCCESS: '[Users] CONFIRM_EMAIL_SUCCESS'
};
