import { API } from './api';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../const';

export const authService = {
  login,
  logout,
  currentUser,
  forgotPassword,
  resetPassword,
  register
};

function login(username, password) {

    return API.post('/auth/login', {
        userName: username,
        password: password,
        grant_type: 'password',
      })
      .then(resp => {
          console.log(resp);
          switch (resp.status) {
            case 200:
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(ACCESS_TOKEN_KEY, resp.data.accessToken);
                localStorage.setItem(REFRESH_TOKEN_KEY, resp.data.refreshToken);
                return resp.data.accessToken;
            case 401:
                logout();
                return Promise.reject(resp);
            default:
                return resp.data;
          }
      })
      .catch(error => {
        return Promise.reject(error);
      });
}

function register(user) {
  return API.post('/auth/sign-up', {
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    password: user.password
  })
    .then(resp => {
      return resp?.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

function logout() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

function forgotPassword(email, userName) {
    return API.post('/auth/request-pass', {
        email,
        userName
    })
    .then(resp => {
        return resp?.data;
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

function resetPassword(userId, code, password) {
    return API.post('/users/reset-password', {
        userId,
        code,
        password
    })
    .then(resp => {
        return resp?.data;
    })
    .catch(error => {
        return Promise.reject(error);
    });
}

function currentUser() {
    return API.get('/users/current')
            .then(resp => {
                return resp?.data;
            })
            .catch(error => {
                console.log(error);
            });
}
