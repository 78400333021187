import {statisticsService} from '../services';
import {statisticsConsts} from '../const';
import {errorToast} from '../utils';

export const statistictsActions = {
  getStatistics,
  getAvg,
};

function getStatistics() {
  return (dispatch) => {
    dispatch(request());

    statisticsService
      .getStatistics()
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: statisticsConsts.LOAD_REQUEST};
  }
  function success(data) {
    return {type: statisticsConsts.LOAD_SUCCESS, data};
  }
  function failure(error) {
    return {type: statisticsConsts.LOAD_FAILURE, error};
  }
}

function getAvg() {
  return (dispatch) => {
    dispatch(request());

    statisticsService
      .getAvg()
      .then(
        (resp) => {
          dispatch(success(resp));
        },
        (error) => {
          dispatch(failure(error));
          errorToast(error);
        }
      )
      .catch((error) => {
        dispatch(failure(error));
        errorToast(error);
      });
  };

  function request() {
    return {type: statisticsConsts.AVG_LOAD_REQUEST};
  }
  function success(data) {
    return {type: statisticsConsts.AVG_LOAD_SUCCESS, data};
  }
  function failure(error) {
    return {type: statisticsConsts.AVG_LOAD_FAILURE, error};
  }
}
