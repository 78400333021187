import { congregationService } from '../services';
import { congregationConsts } from '../const';

export const congregationActions = {
  loadCongregations,
  addCongregation
};

function loadCongregations() {

    return dispatch => {
        dispatch(request());

        congregationService.loadCongregations()
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(failure(error));
                }
            )
            .catch(error => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: congregationConsts.LOAD_REQUEST }; }
    function success(congregations) { return { type: congregationConsts.LOAD_SUCCESS, congregations }; }
    function failure(error) { return { type: congregationConsts.LOAD_FAILURE, error }; }
}

function addCongregation() {

}
